import React from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { ReactComponent as Mobile } from '../images/svgs/mobile-white.svg';
import { contact } from '../contact';

const Panel = styled.div`
    background: #fcfcfc;
    border-radius: 15px;
    padding: 20px 42px;
    margin-top: 32px;
    position: relative;
    ul {
        list-style: none;
        padding-bottom: 25px;
        li {
            margin-bottom: 24px;
        }
    }
    p:first-of-type {
        text-align: center;
        margin-bottom: 60px;
        font-weight: 600;
        font-size: 20px;
        line-height: 144%;
        text-transform: capitalize;
        color: ${colors.gray5};
    }
    p:first-of-type:after {
        content: '';
        display: block;
        margin: 0 auto;
        position: relative;
        top: 16px;
        width: 229.24px;
        height: 0px;
        border: 1px solid ${colors.gray2};
    }
    a {
        width: inherit;
    }
    button {
        padding: 16px 0px;
        color: white;
        border-radius: 0px 0px 15px 15px;
        bottom: 0px;
        border: none;
        width: 100%;
        position: absolute;
        left: 0;
        background: ${colors.blue3};
        display: flex;
        justify-content: center;
        align-items: center;
        b {
            margin: 0 16px;
        }
        svg {
            fill: white;
        }
        svg:first-of-type {
            padding-right: 0px;
            width: 18px;
        }
    }
    @media ${device.laptop} {
    }
`;

export const DentalPlans = () => {
    return (
        <Panel>
            <p>Dental Plans</p>
            <div></div>
            <ul>
                <li>
                    <b>2 </b>Exams
                </li>
                <li>
                    <b>2 </b>Cleanings
                </li>
                <li>
                    <b>2 </b>Sets of X-Rays
                </li>
                <li>
                    <b>2 </b>Flouride Varnish
                </li>
                <li>
                    <b>2 </b>Emergency Visits
                </li>
                <li>
                    <b>10% off </b>Ortho.
                </li>
                <li>
                    <b>20% off </b>All Additional Treatment
                </li>
            </ul>
            <a href={`tel:${contact.phone}`} target="_blank" rel="noopener noreferrer">
                <button>
                    {' '}
                    <Mobile />
                    <b>Contact Us</b>
                </button>
            </a>
        </Panel>
    );
};
