import React, { useState } from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { ReactComponent as Arrow } from '../images/svgs/arrow-blue.svg';

const faq = [
    {
        question: 'What is the best toothbrush for kids 9-13? Do you recommend the Sonic?',
        answer: 'By the time your child reaches 9-10 years old, many adult teeth are now present and an adult toothbrush/toothpaste can be used. Any soft-bristled toothbrush should be used two times a day for two minutes.',
    },
    {
        question: 'How can parents help prevent tooth decay?',
        answer: 'Parents should take their children to the dentist regularly, beginning with the eruption of the first tooth. Then, the dentist can recommend a specific program of brushing, flossing, and other treatments for parents to supervise and teach to their children. These home treatments, when added to regular dental visits and a balanced diet, will help give your child a lifetime of healthy habits. ',
        link: 'More: Preventive Dentistry',
        path: 'https://store.aapd.org/index.php/patient-education.html',
    },
    {
        question: 'How safe are dental X-rays?',
        answer: 'There is very little risk in dental X-rays. Pediatric dentists are especially careful to limit the amount of radiation to which children are exposed. Lead aprons and high-speed film are used to ensure safety and minimize the amount of radiation. ',
        link: 'More: X-Ray Use and Safety',
        path: 'https://store.aapd.org/index.php/patient-education.html',
    },
    {
        question: 'What should I do if my child falls and knocks out a permanent tooth?',
        answer: 'The most important thing to do is to remain calm. Then find the tooth. Hold it by the crown rather than the root and try to reinsert it in the socket. If that is not possible, put the tooth in a glass of milk and take your child and the glass immediately to the pediatric dentist.',
        link: 'More: Emergency Dental Care',
        path: 'https://store.aapd.org/index.php/patient-education.html',
    },
    {
        question: `What can I do to protect my child's teeth during sporting events?`,
        answer: `Soft plastic mouthguards can be used to protect a child's teeth, lips, cheeks and gums from sport related injuries. A custom-fitted mouthguard developed by a pediatric dentist will protect your child from injuries to the teeth, face and even provide protection from severe injuries to the head.`,
        link: `More: Mouth Protectors`,
        path: `https://store.aapd.org/index.php/patient-education.html`,
    },
    {
        question: `How do I know if my child is getting enough fluoride?`,
        answer: `Have your pediatric dentist evaluate the fluoride level of your child's primary source of drinking water. If your child is not getting enough fluoride internally through water (especially if the fluoride level is deficient or if your child drinks bottled water without fluoride), then your pediatric dentist may prescribe fluoride supplements. `,
        link: `More: Enamel Fluorosis`,
        path: `https://store.aapd.org/index.php/patient-education.html`,
    },
    {
        question: `How do dental sealants work?`,
        answer: `Sealants work by filling in the crevasses on the chewing surfaces of the teeth. This shuts out food particles that could get caught in the teeth, causing cavities. The application is fast and comfortable and can effectively protect teeth for many years.`,
        link: `More: Sealants`,
        path: `https://store.aapd.org/index.php/patient-education.html`,
    },
    {
        question: `Toothpaste: when should we begin using it and how much should we use?`,
        answer: `The sooner the better! Starting at birth, clean your child’s gums with a soft infant toothbrush or cloth and water. Parents should use a tiny smear of fluoride toothpaste to brush baby teeth twice daily as soon as they erupt and a soft, age-appropriate sized toothbrush. Once children are 3 to 6 years old, then the amount should be increased to a pea-size dollop and perform or assist your child’s toothbrushing. Remember that young children do not have the ability to brush their teeth effectively.  Children should spit out and not swallow excess toothpaste after brushing.`,
        link: `More: Sealants`,
        path: `https://store.aapd.org/index.php/patient-education.html`,
    },
    {
        question: `How often does my child need to see the pediatric dentist?`,
        answer: `A check-up every six months is recommended in order prevent cavities and other dental problems. However, your pediatric dentist can tell you when and how often your child should visit based on their personal oral health.`,
        link: `More: Regular Dental Visits`,
        path: `https://store.aapd.org/index.php/patient-education.html`,
    },
    {
        question: `What should I use to clean my baby's teeth?`,
        answer: `A toothbrush will remove plaque bacteria that can lead to decay. Any soft-bristled toothbrush with a small head, preferably one designed specifically for infants, should be used at least once a day at bedtime.`,
        link: `More: More Dental Care for you Baby`,
        path: `https://store.aapd.org/index.php/patient-education.html`,
    },
    {
        question: `How can I prevent decay caused by nursing?`,
        answer: `Avoid nursing children to sleep or putting anything other than water in their bed-time bottle. Also, learn the proper way to brush and floss your child's teeth. Take your child to a pediatric dentist regularly to have his/her teeth and gums checked. The first dental visit should be scheduled by your child's first birthday.`,
        link: `More: Dental Care For Your Baby`,
        path: `https://store.aapd.org/index.php/patient-education.html`,
    },
    {
        question: `Are thumbsucking and pacifier habits harmful for a child's teeth?`,
        answer: `Thumb and pacifier sucking habits will generally only become a problem if they go on for a very long period of time. Most children stop these habits on their own, but if they are still sucking their thumbs or fingers past the age of three, a mouth appliance may be recommended by your pediatric dentist.`,
        link: `More: Dental Care For Your Baby`,
        path: `https://store.aapd.org/index.php/patient-education.html`,
    },
    {
        question: `What should I do if my child has a toothache?`,
        answer: `First, rinse the irritated area with warm salt water and place a cold compress on the face if it is swollen. Give the child acetaminophen for any pain, rather than placing aspirin on the teeth or gums. Finally, see a dentist as soon as possible.`,
        link: `More: Emergency Dental Care`,
        path: `https://store.aapd.org/index.php/patient-education.html`,
    },
    {
        question: `Are baby teeth really that important to my child?`,
        answer: `Primary, or "baby," teeth are important for many reasons. Not only do they help children speak clearly and chew naturally, they also aid in forming a path that permanent teeth can follow when they are ready to erupt.`,
        link: `More: The Pediatric Dentist`,
        path: `https://store.aapd.org/index.php/patient-education.html`,
    },
    {
        question: `How do I find a pediatric dentist in my hometown?`,
        answer: `Click on the Find A Pediatric Dentist button located above. Enter your city, state and zip for a list of pediatric dentists nearest you. If your entries result in "no matching pediatric dentist records were found," broaden your search by entering the state only or nearest city and state.`,
    },
    {
        question: `What is the difference between a pediatric dentist and a family dentist?`,
        answer: `Pediatric dentists are the pediatricians of dentistry. A pediatric dentist has two to three years specialty training following dental school and limits his/her practice to treating children only. Pediatric dentists are primary and specialty oral care providers for infants and children through adolescence, including those with special health needs.`,
    },
    {
        question: `When should I take my child to the dentist for the first check-up?`,
        answer: `In order to prevent dental problems, your child should see a pediatric dentist when the first tooth appears, or no later than his/her first birthday.`,
    },
    {
        question: `What should I use to clean my baby's teeth?`,
        answer: `A toothbrush will remove plaque bacteria that can lead to decay. Any soft-bristled toothbrush with a small head, preferably one designed specifically for infants, should be used at least once a day at bedtime.`,
        link: `More: Dental Care For Your Baby`,
        path: `https://store.aapd.org/index.php/patient-education.html`,
    },
];

const Main = styled.div`
    align-self: start;
    @media ${device.laptop} {
        width: 1064px;
        margin-top: 72px;
        margin-bottom: 72px;
    }
`;

const Question = styled.div`
    border-bottom: 2px solid ${colors.blue2};
    position: relative;
    padding: 8px 0px;
    svg {
        position: absolute;
        top: 34px;
        transform: rotate(180deg);
        right: 8px;
        transition: 0.2s ease-in;
        transform: rotate(0deg);
    }
    .rotate {
        transform: rotate(180deg);
    }
    > p:first-of-type {
        padding-right: 35px;
        font-weight: 700;
        cursor: pointer;
        font-size: 18px;
        line-height: 27px;
        color: ${colors.blue4};
    }
    a {
        p {
            color: ${colors.blue3};
            text-decoration: underline;
        }
    }
    div {
        transition: 0.2s ease-in;
        opacity: ${({ active, index, i }) => (index === i && active ? '1' : '0')};
        height: ${({ active, index, i }) => (index === i && active ? '100%' : '00px')};
    }
    /* .open {
        opacity: 1;
    }
    .close {
        height: 0px;
        opacity: 0;
    } */
`;

export const FaqPanels = () => {
    const [active, setActive] = useState(false);
    const [index, setIndex] = useState<number | null>(null);

    const menuClick = i => {
        setIndex(i);
        if (i !== index) {
            setActive(true);
        } else if (i === index) {
            setActive(active => !active);
        } else {
            setActive(false);
        }
    };

    return (
        <Main className="container">
            <p>
                {`Here at Clermont Kid's Dentistry we strive to answer all your important questions.`}
            </p>
            {faq.map((f, i) => (
                <Question active={active} index={index} i={i} key={f.question}>
                    <p
                        onClick={() => {
                            menuClick(i);
                        }}
                    >
                        {f.question}
                    </p>
                    <Arrow
                        className={`${active && index === i ? '' : 'rotate'}`}
                        onClick={() => {
                            menuClick(i);
                        }}
                    />
                    <div>
                        <p
                            onClick={() => {
                                setActive(false);
                                setIndex(null);
                            }}
                        >
                            {f.answer}
                        </p>
                        {f.link && (
                            <a href={f.path} target="_blank" rel="noopener noreferrer">
                                <p>{f.link}</p>
                            </a>
                        )}
                    </div>
                </Question>
            ))}
        </Main>
    );
};
