import React from 'react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { colors, device } from './layout/GlobalStyles';

const DentalSquadContainer = styled.div`
    h2 {
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        text-align: center;
        text-transform: capitalize;
        color: ${colors.blue3};
    }
    @media ${device.tablet} {
        .flex-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
        }
    }
    @media ${device.laptop} {
        h2 {
            margin-top: 72px;
        }
    }
`;

const ImageContainer = styled.div`
    margin: 32px 0px 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
        width: 323px;
        border-radius: 200px;
    }
    P {
        align-self: center;
        margin: 0;
    }
    p:first-of-type {
        margin-top: 16px;
        font-weight: 600;
        font-size: 24px;
        line-height: 35px;
        color: ${colors.gray5};
        text-align: center;
        text-transform: capitalize;
    }
    p:last-of-type {
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: ${colors.gray3};
        text-align: center;
        text-transform: capitalize;
    }
    @media ${device.tablet} {
        margin: 32px 10px 0px;
    }
`;

export const DentalSquad = () => {
    return (
        <DentalSquadContainer>
            <h2>Doctors</h2>
            <ImageContainer>
                <StaticImage
                    src="../images/dental-squad/dr-tartibi.jpg"
                    placeholder="none"
                    quality={100}
                    alt="dr. tartibi"
                />
                <p>Dr. Tartibi</p>
                <p>D.M.D.</p>
            </ImageContainer>
            <h2>Dental Assistants</h2>
            <div className="flex-container">
                <ImageContainer>
                    <StaticImage
                        src="../images/dental-squad/janette.jpg"
                        placeholder="none"
                        quality={100}
                        alt="janette"
                    />
                    <p>Janette</p>
                    <p>Lead Dental Assistant</p>
                </ImageContainer>
                <ImageContainer>
                    <StaticImage
                        src="../images/dental-squad/joanna.jpg"
                        placeholder="none"
                        quality={100}
                        alt="joanna"
                    />
                    <p>Joanna</p>
                    <p>Dental Assistant</p>
                </ImageContainer>
                <ImageContainer>
                    <StaticImage
                        src="../images/dental-squad/lexi.jpg"
                        placeholder="none"
                        quality={100}
                        alt="lexi"
                    />
                    <p>Lexi</p>
                    <p>Dental Assistant</p>
                </ImageContainer>
            </div>
        </DentalSquadContainer>
    );
};
