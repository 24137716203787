import React from 'react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { colors, device } from './layout/GlobalStyles';
import { ReactComponent as Star } from '../images/svgs/star.svg';

const reviews = [
    {
        name: 'Yakarta P.',
        review: '“Friendly staff and very helpful on keeping special need kids feel comfortable and safe in thier care thank you”',
    },
    {
        name: 'Sabrina S.',
        review: `“The office was clean and the staff were so friendly. My child loved her experience and cant wait to go back for her 6 month checkup. She didn't even know she was getting a cavity fixed.”`,
    },
    {
        name: 'Ariana H.',
        review: `“The office was clean and the staff were so friendly. My child loved her experience and cant wait to go back for her 6 month checkup. She didn't even know she was getting a cavity fixed.”`,
    },
    {
        name: 'Nicole G.',
        review: `““Seriously love this place! My daughter is 3 and it was her first dentist apt so i Of course was very nervous but it actually went amazing. They are so kind and fun and my daughter absolutely loved it! Definitely recommend them!”`,
    },
    {
        name: 'Sherriza N.',
        review: `“Staff and doctor are all Angels. My son received expert care and was completely comfortable the entire time.”`,
    },
    {
        name: 'Jess I.',
        review: `“Amazing! Dr. T is so patient and great with the kids. After our appointment today (kids ranging 4-13 yr old) they can’t wait to come back! Thank you Dr.T and team for making dentistry a positive experience for my kids!”`,
    },
    {
        name: 'George F.',
        review: `“We had an emergency visit and the team worked us in very quickly and were excellent with my daughter. Thank you for your care!”`,
    },
    {
        name: 'Michele R.',
        review: `“We had such a great experience at clermont kids dentistry! My daughter loved the dentist and felt very comfortable with him. We definitely found a great place. We will be coming back for sure!”`,
    },
];

const ReviewPanelsContainer = styled.div`
    margin: 0px 0px 32px;
    @media ${device.laptop} {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 64px 0px 32px;
    }
`;

const Panel = styled.div`
    margin-top: 32px;
    padding: 24px;
    background: #fcfcfc;
    border: 1px solid #d2dee2;
    border-radius: 15px;
    p {
        margin: 0;
    }
    div {
        margin: 16px 0px;
    }
    p:first-of-type {
        color: ${colors.blue3};
        font-weight: bold;
    }
    @media ${device.laptop} {
        width: 512px;
        min-height: 220px;
        margin: 16px;
    }
`;

export const ReviewPanels = () => {
    return (
        <ReviewPanelsContainer>
            {reviews.map(review => (
                <Panel>
                    <p>{review.name}</p>
                    <div>
                        <Star />
                        <Star />
                        <Star />
                        <Star />
                        <Star />
                    </div>
                    <p>{review.review}</p>
                </Panel>
            ))}
        </ReviewPanelsContainer>
    );
};
