import React from 'react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { colors, device } from './layout/GlobalStyles';
import { ReactComponent as Name } from '../images/svgs/girl.svg';

const StyledCredentials = styled.div`
    h2 {
        text-align: center;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        text-transform: capitalize;
        color: ${colors.gray5};
    }
    section {
        p:first-of-type {
            text-align: center;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            text-transform: capitalize;
        }
    }
    section:first-of-type {
        > div {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            gap: 20px;
            margin-top: 32px;
            div {
                img {
                    width: 120px;
                    border-radius: 0;
                }
            }
        }
    }
    section:last-of-type {
        margin-top: 54px;
        .top-certs {
            display: flex;
            flex-direction: column;
            margin: 45px auto 64px;
            padding: 0 1px;
            div:last-of-type {
                position: relative;
                top: 16px;
            }
        }
        .bottom-certs {
            position: relative;
            left: 20px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 32px;
            gap: 0px;
            div:first-of-type + div {
                position: relative;
                right: 40px;
            }
            div {
                border-radius: 0;
            }
            img {
                width: 75%;
                border-radius: 0;
            }
        }
    }
    @media ${device.tablet} {
        section:first-of-type {
            > div {
                gap: 20px;
                margin: 32px auto 0;
                width: 400px;
                div {
                    width: 160px;
                    img {
                        width: 160px;
                        border-radius: 0;
                    }
                }
            }
        }
        section:last-of-type {
            margin-bottom: 32px;
        }
        .top-certs {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 64px auto;
        }
    }
    @media ${device.laptop} {
        h2 {
            font-size: 32px;
        }
        section:first-of-type {
            align-items: center;
            p:first-of-type {
                text-align: left;
            }
        }
        section:first-of-type {
            display: flex;
            > div {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                justify-content: center;
                gap: 32px;
                margin-top: 32px;
                div {
                    min-width: 300px;
                    img {
                        min-width: 300px;
                        border-radius: 0;
                    }
                }
            }
        }
        section {
            width: 1064px;
            p:first-of-type {
                font-size: 32px;
            }
        }
        .top-certs {
            justify-content: space-around;
        }
    }
`;

export const Credentials = () => {
    return (
        <StyledCredentials>
            <h2>Educational Background</h2>
            <section>
                <article>
                    <ul>
                        <li>Languages: English - Spanish</li>
                        <li>University of Central Florida BS</li>
                        <li>Barry University MS</li>
                        <li>Boston University School of Dental Medicine D.M.D with Honors</li>
                        <li>
                            Boston University/Franciscan Children’s’ Pediatric Dentistry Residency
                        </li>
                    </ul>
                </article>
                <div>
                    <StaticImage
                        src="../images/ucf.jpg"
                        alt="UCF"
                        placeholder="none"
                        quality={100}
                    />
                    <StaticImage
                        src="../images/barry-university.jpg"
                        alt="Barry University Logo"
                        placeholder="none"
                        quality={100}
                    />
                    <StaticImage
                        src="../images/boston-university.jpg"
                        alt="Boston University logo"
                        placeholder="none"
                        quality={100}
                    />
                </div>
            </section>
            <section>
                <article>
                    <p>Certifications</p>
                    <p>
                        Dr. F. Tartibi is a Board Certified Pediatric Dentist and has successfully
                        completed a rigorous examination process to obtain the highest level of
                        training for your kiddos! Dr. Tartibi is an active member of the American
                        Academy of Pediatric Dentistry, Florida Academy of Pediatric Dentistry and
                        Florida Dental Association.
                    </p>
                </article>
                <div className="top-certs">
                    <StaticImage
                        src="../images/board-certified.png"
                        alt="Board Certified American Board of Pediatric Dentistry logo"
                        placeholder="none"
                        quality={100}
                    />
                    <StaticImage
                        src="../images/americas-pediatric-dentist.png"
                        alt="Americas Pediatric Dentists logo"
                        placeholder="none"
                        quality={100}
                    />
                </div>
                <div className="bottom-certs">
                    <StaticImage
                        src="../images/aa.png"
                        alt="American Academy of Pediatric Dentistry logo"
                        placeholder="none"
                        quality={100}
                    />
                    <StaticImage
                        src="../images/ada.png"
                        alt="American Dental Association logo"
                        placeholder="none"
                        quality={100}
                    />
                    <StaticImage
                        src="../images/fapd.png"
                        alt="Florida Academcy Pediatric Dentistry logo"
                        placeholder="none"
                        quality={100}
                    />
                    <StaticImage
                        src="../images/fda.png"
                        alt="Florida Dental Association logo"
                        placeholder="none"
                        quality={100}
                    />
                </div>
            </section>
        </StyledCredentials>
    );
};
