import React from 'react';
import styled from '@emotion/styled';
import { colors } from './layout/GlobalStyles';
import { ReactComponent as Marker } from '../images/svgs/marker-contact.svg';
import { ReactComponent as Email } from '../images/svgs/email.svg';
import { ReactComponent as Phone } from '../images/svgs/phone.svg';
import { ReactComponent as Fax } from '../images/svgs/fax.svg';
import { contact } from '../contact';

type Props = {
    color: string;
};

const Container = styled.div<Props>`
    article {
        display: flex;
        align-items: center;
    }
    a {
    }
    p {
        color: #151513;
        text-decoration: underline;
        max-width: 290px;
    }
    svg {
        fill: ${({ color }) => color === 'yellow' && `${colors.yellow4}`};
        fill: ${({ color }) => color === 'blue' && `${colors.blue4}`};
    }
    div {
        margin-right: 16px;
        border-radius: 5px;
        /* ask smiley */
        background: ${({ color }) => color === 'blue' && `${colors.blue2}`};
        background: ${({ color }) => color === 'yellow' && `${colors.yellow2}`};
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
    }
`;

export const ContactInfo: React.FC<Props> = ({ color }) => {
    return (
        <Container color={color}>
            {' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://goo.gl/maps/G6mu89mo6xVAkmwk8"
            >
                <article>
                    <div>
                        <Marker />
                    </div>
                    <p>{contact.location}</p>
                </article>
            </a>
            <a target="_blank" rel="noopener noreferrer" href={`mailto:${contact.email}`}>
                <article>
                    <div>
                        <Email />
                    </div>
                    <p>{contact.email}</p>
                </article>
            </a>
            <a rel="noopener noreferrer" href={`tel:${contact.phone}`}>
                <article>
                    <div>
                        <Phone />
                    </div>
                    <p>{contact.phoneDisplay}</p>
                </article>
            </a>
            <a rel="noopener noreferrer" href={`tel:${contact.faxDisplay}`}>
                <article>
                    <div>
                        <Fax />
                    </div>
                    <p>{contact.faxDisplay}</p>
                </article>
            </a>
        </Container>
    );
};
