/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { Nav } from '../components/layout/Nav';
import { useContext } from 'react';
import { siteContext } from './SiteContextProvider';
import { ThemeProvider } from '@emotion/react';
import { GlobalStyles, device, colors } from '../components/layout/GlobalStyles';
import { Footer } from '../components/layout/Footer';
import { MDXProvider } from '@mdx-js/react';
import { ButtonPrimary, SecondaryButton } from '../components/layout/StyledComponents';
import { ReactComponent as Arrow } from '../images/svgs/arrow-right-yellow.svg';
import { DentalPlans } from '../components/Dental-Plans';
import styled from '@emotion/styled';
import { DentalSquad } from '../components/Dental-Squad';
import { ContactUs } from '../components/Contact-Us';
import { Credentials } from '../components/Credentials';
import { ReviewPanels } from '../components/Review-Panels';
import { FaqPanels } from '../components/Faq-Panels';
import { BlogPanels } from '../components/Blog-Panels';

const Body = styled.main`
    padding-top: 100px;
    @media ${device.laptop} {
        padding-top: 140px;
    }
`;

export const DefaultLayout = ({ children }: { children: React.ReactNode }) => {
    const { setActive } = useContext(siteContext);

    const shortcodes = {
        FaqPanels,
        Credentials,
        DentalSquad,
        DentalPlans,
        ReviewPanels,
        ButtonPrimary,
        SecondaryButton,
        BlogPanels,
        Arrow,
    };
    return (
        <main>
            <ThemeProvider theme={colors} />
            <GlobalStyles />
            <Nav />
            <Body className="mobile-body" onMouseOver={() => setActive(false)}>
                <MDXProvider components={shortcodes}>{children}</MDXProvider>
                <ContactUs />
                <Footer />
            </Body>
        </main>
    );
};
