import { css, Global } from '@emotion/react';
import React from 'react';

export const colors = {
    blue2: '#7BD2F6',
    blue3: '#008EDE',
    blue4: '#136AB0',
    gray2: '#D2DEE2',
    gray3: '#A8B4B9',
    gray4: '#7B878F',
    gray5: '#353633',
    pink: '#EE596B',
    green: '#26A312',
    teal: '#0C9372',
    yellow1: '#F5EACD',
    yellow2: '#F6CE65',
    yellow3: '#E1A200',
    yellow4: '#BD8A06',
    white: '#FFFFFF',
};

const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '400px',
    tablet: '768px',
    laptop: '1080px',
    laptopL: '1280px',
    desktop: '1540px',
    desktopL: '1820px',
};

export const device = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktopL})`,
};

export const GlobalStyles: React.FC = () => {
    return (
        <Global
            styles={css`
                html {
                    box-sizing: border-box;
                    font-size: 100%;
                    overflow-x: hidden;
                    -ms-text-size-adjust: 100%;
                    -webkit-text-size-adjust: 100%;
                    scroll-behavior: smooth;
                }
                html,
                body {
                    scroll-behavior: smooth;
                }
                p,
                li,
                h2,
                h3 {
                    color: ${colors.gray5};
                }

                body {
                    background: ${colors.white};
                    font-weight: normal;
                    font-size: 1rem;
                    word-wrap: break-word;
                    font-kerning: normal;
                    overflow-x: hidden;
                    word-wrap: break-word;
                    margin: 0;
                    font-family: 'Poppins';
                    -webkit-font-smoothing: antialiased;
                }
                a {
                    color: ${colors.gray5};
                    text-decoration: none;
                }
                .image {
                    position: relative;
                }
                h2 {
                    text-transform: uppercase;
                }
                *,
                *:before,
                *:after {
                    box-sizing: border-box;
                }
                ul {
                    padding-left: 0;
                }
                h1 {
                    font-size: 32px;
                    line-height: 48px;
                }
                p {
                    span {
                        font-weight: bold;
                    }
                }
                .center-text {
                    text-align: center;
                }
                h2,
                h3 {
                    font-weight: 700;
                    letter-spacing: 0.22em;
                    font-size: 28px;
                    line-height: 37.1px;
                }
                button,
                input,
                optgroup,
                select,
                textarea {
                    margin: 0;
                }
                button {
                    cursor: pointer;
                }
                input,
                textarea {
                    border: none;
                    outline: 2px solid ${colors.gray2};
                    padding: 15px 24px 15px 12px;
                    transition: 0.2s ease-in;
                    border-radius: 5px;
                }
                input[type='text']:focus {
                    border: none;
                    outline: 2px solid ${colors.blue3};
                    border-radius: 5px;
                }
                input[type='date'] {
                    border: none;
                    outline: 2px solid ${colors.gray2};
                    padding: 15px 24px 15px 12px;
                    transition: 0.2s ease-in;
                    border-radius: 5px;
                }
                input[type='time'] {
                    border: none;
                    outline: 2px solid ${colors.gray2};
                    padding: 15px 24px 15px 12px;
                    transition: 0.2s ease-in;
                    border-radius: 5px;
                }

                ::-webkit-input {
                    border: none;
                    outline: 2px solid ${colors.gray2};
                    padding: 15px 24px 15px 12px;
                    transition: 0.2s ease-in;
                    border-radius: 5px;
                }

                ::-webkit-input-placeholder {
                    color: inherit;
                    opacity: 0.54;
                }
                ::-webkit-file-upload-button {
                    -webkit-appearance: button;
                    font: inherit;
                }
                input:focus:invalid {
                    outline: 2px solid red; /* oranges! yey */
                }
                textarea:focus {
                    outline: 2px solid ${colors.blue3}; /* oranges! yey */
                    border: none;
                }
                .image-container {
                    position: relative;
                }
                .container {
                    padding: 0px 16px;
                }

                .underlayer {
                    position: absolute;
                    top: 0em;
                    filter: blur(20px);
                    width: 74%;
                    left: 2em;
                    top: 2.4em;
                }

                @media ${device.mobileL} {
                }
                @media ${device.tablet} {
                    h1 {
                        font-size: 42px;
                        line-height: 54px;
                    }
                    h2,
                    h3 {
                        font-size: 36px;
                        line-height: 48.1px;
                    }

                    .margin {
                        margin: 5em 0em;
                    }
                    .container {
                        max-width: 720px;
                        margin: 0 auto;
                    }
                }
                @media ${device.laptop} {
                    h1 {
                        font-size: 56px;
                        line-height: 84px;
                    }
                    h2,
                    h3 {
                        font-size: 40px;
                        line-height: 53px;
                    }
                    .container {
                        max-width: 1020px;
                    }
                }

                @media ${device.laptopL} {
                    .margin {
                        margin: 7em 0em;
                    }
                    .special-case {
                        padding: 0.5em 0em;
                        text-align: center;
                    }

                    .container {
                        max-width: 1280px;
                        margin: 0 auto;
                    }
                    h1 {
                        font-size: 64px;
                        line-height: 66px;
                    }
                    a {
                        text-decoration: none;
                    }
                    h2 {
                        font-style: normal;

                        font-size: 40px;
                        line-height: 60px;
                    }
                }
                @media ${device.desktop} {
                    h2 {
                        font-size: 48px;
                    }
                }
                @media ${device.desktopL} {
                    p {
                        font-size: 1.2em;
                    }
                    h1 {
                        font-size: 60px;
                        line-height: 66px;
                    }
                    h2 {
                        font-size: 58px;
                        line-height: 66px;
                    }
                    h3 {
                        font-size: 48px;
                    }

                    .container {
                        max-width: 1265px;
                        margin: 0 auto;
                    }
                }
            `}
        />
    );
};
