export const navMenu = [
    {
        name: 'About Us',
        path: '/about-us/',
        menu: [
            { name: 'Who We Are', path: '/about-us/who-we-are/' },
            { name: 'Why Choose Us', path: '/about-us/why-choose-us/' },
            { name: 'Dr. Faraz Tartibi', path: '/about-us/dr-farad-tartibi/' },
            {
                name: 'Our Dental Squad',
                path: '/about-us/our-dental-squad/',
            },
            {
                name: 'Blog',
                path: '/about-us/blog/',
            },
        ],
    },
    {
        name: 'Our Services',
        path: '/our-services/',
        menu: [
            {
                name: 'Orthodontics',
                path: '/our-services/orthodontics/',
            },
            {
                name: 'Preventive Dentistry',
                path: '/our-services/preventive-dentistry/',
            },
            {
                name: 'Restorative dentistry',
                path: '/our-services/restorative-dentistry/',
            },
            {
                name: 'Space maintainers',
                path: '/our-services/space-maintainers/',
            },

            // {
            //     name: 'Teeth Cleanings',
            //     path: '/our-services/preventive-dentistry/teeth-cleaning/',
            // },
            {
                name: 'Mouth Guards',
                path: '/our-services/mouth-guards/',
            },
            // {
            //     name: 'Sealants',
            //     path: '/our-services/preventive-dentistry/sealants/',
            // },
            {
                name: 'Dental Emergencies',
                path: '/our-services/dental-emergencies/',
            },
            // {
            //     name: 'Dental Fillings',
            //     path: '/our-services/restorative-dentistry/dental-fillings/',
            // },
            // {
            //     name: 'Digital X-Rays',
            //     path: '/our-services/preventive-dentistry/digital-x-rays/',
            // },
            // {
            //     name: 'Dental Crowns',
            //     path: '/our-services/restorative-dentistry/dental-crowns/',
            // },
            // {
            //     name: 'Fluoride Treatment',
            //     path: '/our-services/preventive-dentistry/flouride-treatment/',
            // },
            // {
            //     name: 'Pulpotomy Therapy',
            //     path: '/our-services/restorative-dentistry/pulpotomy-therapy/',
            // },
            // {
            //     name: 'Special Needs',
            //     path: '/our-services/special-needs/',
            // },
            {
                name: 'Nitrous / IV Sedation',
                path: '/our-services/nitrous-sedation/',
            },
            {
                name: 'Lip/Tongue Tie',
                path: '/our-services/lip-tongue-tie/',
            },
            {
                name: 'Tooth Fairy Visits',
                path: '/our-services/tooth-fairy-visits/',
            },
        ],
    },
    {
        name: 'Resources',
        path: '/resources/',
        menu: [
            { name: 'Why a Pediatric Dentist', path: '/resources/why-a-pediatric-dentist/' },
            { name: 'COVID-19 Information', path: '/resources/covid-19-info/' },
            { name: 'Financial Info', path: '/resources/financial-info/' },
            { name: 'Dental Plans', path: '/resources/dental-plans/' },
            { name: 'Post-Op Care', path: '/resources/post-op-care/' },
        ],
    },

    {
        name: 'For Patients',
        path: '/for-patients/',
        menu: [
            {
                name: 'Your First visit',
                path: '/for-patients/your-first-visit/',
            },
            { name: 'Patient Reviews', path: '/for-patients/patient-reviews/' },
            { name: 'FAQ', path: '/for-patients/faq/' },
            // {
            //     name: 'Patient Forms',
            //     path: 'https://clermontkidsdentistry.com/sites/clermontkidsdentistry.com/files/forms/new-patient-form-ckd.pdf',
            // },
        ],
    },
];

export const servicesMenu = [
    {
        name: 'General Dentistry',
        path: 'general-dentistry/',
        menu: [
            { name: 'Oral Cancer Screening', path: 'oral-dentistry/oral-cancer-screaning/' },
            { name: 'Same Day Crowns', path: 'general-dentistry/same-day-crowns/' },
            { name: 'Sealants', path: 'general-dentistry/sealants/ ' },
            { name: 'Saturday Dentist', path: 'general-dentistry/saturday-dentist/' },
            { name: 'Root Canals', path: 'general-dentistry/root-canals/' },
            { name: 'Home Care', path: 'general-dentistry/home-care/' },
            { name: 'Night Guard', path: 'general-dentistry/night-guard/ ' },
            { name: 'Teeth Extractions', path: 'general-dentistry/teeth-extractions/' },
            { name: 'Emergency Dentist', path: 'general-dentistry/emergency-dentist/ ' },
            { name: 'Sedation', path: 'sedation-dentistry/' },
            { name: 'Nitrous / IV Sedation', path: 'sedation-dentistry/nitrous-oxide/' },
        ],
    },
    {
        name: 'Periodontics',
        path: 'periodontics/',
        menu: [
            { name: 'Periodontal Therapy', path: 'periodontal-therapy/periodontal-therapy/' },
            { name: 'Deep Teeth Cleaning', path: 'periodontics/deep-teeth-cleaning/' },
            { name: 'Soft Tissue Graft', path: 'periodontics/soft-tissue-graft/' },
            { name: 'LANAP Procedure', path: 'periodontics/lanap-procedure/' },
        ],
    },
    {
        name: 'Orthodontics',
        path: 'orthodontics/',
        menu: [
            { name: 'Ortho Screenings', path: 'orthodontics/ortho-screenings/' },
            { name: 'Invisalign', path: 'cosmetic-dentistry/invisalign/' },
        ],
    },
    {
        name: 'Cosmetic',
        path: 'cosmetic-dentistry/',
        menu: [
            { name: 'Teeth Whitening', path: 'cosmetic-dentistry/teeth-whitening/' },
            { name: 'Porcelain Veneers', path: 'cosmetic-dentistry/porcelain-veneers/   ' },
            { name: 'Onlays', path: 'cosmetic-dentistry/inlays-onlays/' },
            { name: 'Gum Contouring', path: 'cosmetic-dentistry/gum-contouring/' },
        ],
    },

    {
        name: 'Implants',
        path: 'dental-implants/',
        menu: [
            { name: 'Bone Grafting', path: 'oral-dentistry/bone-grafting/' },
            { name: 'Full / Partial Dentures', path: 'dental-implants/full-partial-dentures/' },
            { name: 'Dental Bridges', path: 'dental-implants/dental-bridges/' },
        ],
    },
    {
        name: 'Technology',
        path: 'technology/',
        menu: [
            { name: 'iTero', path: 'technology/itero-intraoral-scanners/' },
            { name: 'Cone Beam / 3D X-Ray', path: 'technology/3d-imaging/' },
            { name: 'Digital X-Rays', path: 'technology/digital-x-rays/' },
            { name: 'Soft Tissue Laser', path: 'technology/soft-tissue-laser/' },
            { name: 'Hard Tissue Laser', path: 'technology/hard-tissue-laser/' },
            { name: 'VELscope', path: 'technology/velscope/' },
        ],
    },
];
