exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-for-patients-tsx": () => import("./../../../src/pages/for-patients.tsx" /* webpackChunkName: "component---src-pages-for-patients-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-services-tsx": () => import("./../../../src/pages/our-services.tsx" /* webpackChunkName: "component---src-pages-our-services-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-templates-alt-template-tsx": () => import("./../../../src/templates/alt-template.tsx" /* webpackChunkName: "component---src-templates-alt-template-tsx" */),
  "component---src-templates-standard-template-tsx": () => import("./../../../src/templates/standard-template.tsx" /* webpackChunkName: "component---src-templates-standard-template-tsx" */)
}

