import styled from '@emotion/styled';
import { colors, device } from './GlobalStyles';
import { ReactComponent as BlueArrowShort } from '../../images/svgs/arrow-short-blue.svg';
import { ReactComponent as YellowArrowShort } from '../../images/svgs/arrow-short-yellow.svg';

interface PrimaryProps {
    fill?: boolean;
}

export const ButtonPrimary = styled.button<PrimaryProps>`
    background: ${({ fill }) => (fill ? `${colors.yellow3}` : `none`)};
    color: ${({ fill }) => (fill ? `${colors.white}` : `${colors.yellow3}`)};
    border: ${({ fill }) => (fill ? `2px solid ${colors.yellow3}` : `2px solid ${colors.yellow3}`)};
    font-size: 16px;
    border-radius: 50px;
    font-weight: 500;
    padding: 14px 28px;
    display: flex;
    align-items: center;
    transition: 0.2s ease-in;

    svg {
        margin-right: 12px;
    }
    &:hover {
        background: ${({ fill }) => (fill ? `none` : `${colors.yellow3}`)};
        color: ${({ fill }) => (fill ? `${colors.yellow3}` : `white`)};
    }
`;

interface Color {
    color: string;
}

const StyledSecondaryButton = styled.button<Color>`
    font-weight: 700;
    font-size: 16px;
    border: none;
    display: flex;
    align-items: center;

    text-transform: capitalize;
    background: none;
    line-height: 24px;
    color: ${({ color }) => color === 'yellow' && `${colors.yellow3}`};
    color: ${({ color }) => color === 'blue' && `${colors.blue3}`};
    svg {
        color: ${({ color }) => color === 'yellow' && `${colors.yellow3}`};
        fill: ${({ color }) => color === 'blue' && `${colors.blue3}`};
        width: 16px;
    }
    div {
        margin-left: 16px;
        padding: 10px;
        display: flex;
        transition: 0.2s ease-in;
        align-items: center;
        justify-content: center;
        border-radius: 200px;
        border: ${({ color }) => color === 'yellow' && `2px solid ${colors.yellow3}`};
        border: ${({ color }) => color === 'blue' && `2px solid ${colors.blue3}`};
    }
    &:hover {
        div {
            transform: translateX(10%);
        }
    }
    @media ${device.laptop} {
        font-size: 18px;
    }
`;

interface Props {
    children: string;
    color: 'yellow' | 'blue';
    className?: string;
}
export const SecondaryButton = ({ children, color }: Props) => {
    return (
        <StyledSecondaryButton color={color}>
            {children}
            {color === 'blue' ? (
                <div>
                    <BlueArrowShort />
                </div>
            ) : (
                <div>
                    <YellowArrowShort />
                </div>
            )}{' '}
        </StyledSecondaryButton>
    );
};
