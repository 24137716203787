import React from 'react';
import styled from '@emotion/styled';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { colors, device } from './layout/GlobalStyles';
import { ReactComponent as Name } from '../images/svgs/girl.svg';
import { SecondaryButton } from './layout/StyledComponents';

const Container = styled.div``;

const Panel = styled.div`
    margin: 32px 0px;
    h2 {
        margin: 16px 0px;
    }
    @media ${device.laptop} {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .text {
            width: 540px;
            margin-left: 30px;
        }
        img {
            width: 344px;
            height: 294px;
        }
    }
    @media ${device.laptopL} {
        .text {
            margin-left: 32px;
            width: 650px;
        }
        img {
            width: 374px;
            height: 294px;
        }
    }
`;

export const BlogPanels = () => {
    const blogs = useStaticQuery(graphql`
        {
            allMdx(
                filter: { frontmatter: { type: { eq: "blog" } } }
                sort: { order: ASC, fields: frontmatter___order }
            ) {
                edges {
                    node {
                        id
                        frontmatter {
                            thumbnail {
                                childImageSharp {
                                    gatsbyImageData(quality: 100, placeholder: NONE)
                                }
                                name
                            }
                            slug
                            title
                            meta {
                                description
                            }
                        }
                    }
                }
            }
        }
    `);
    return (
        <Container className="container">
            {blogs.allMdx.edges.map(blog => (
                <Link key={blog.node.frontmatter.title} to={blog.node.frontmatter.slug}>
                    <Panel>
                        <GatsbyImage
                            alt={blog.node.frontmatter.thumbnail.name}
                            image={blog.node.frontmatter.thumbnail.childImageSharp.gatsbyImageData}
                        />
                        <div className="text">
                            <h2>{blog.node.frontmatter.title}</h2>
                            <p>{blog.node.frontmatter.meta.description}</p>
                            <SecondaryButton color="blue">Continue to Read</SecondaryButton>
                        </div>
                    </Panel>
                </Link>
            ))}
        </Container>
    );
};
