import React from 'react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { colors, device } from '../layout/GlobalStyles';
import { ContactInfo } from '../Contact-Info';
import { ReactComponent as Google } from '../../images/svgs/google.svg';
import { ReactComponent as Facebook } from '../../images/svgs/facebook.svg';
import { ReactComponent as Instagram } from '../../images/svgs/instagram.svg';
import { ReactComponent as Yelp } from '../../images/svgs/yelp.svg';
import { Link } from 'gatsby';
import { ReactComponent as Logo } from '../../images/svgs/logo.svg';

const FooterContainer = styled.div`
    background: ${colors.yellow1};
    margin-top: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .container {
        padding: 36px 0px;
    }
    .footer-logo {
        height: 110px;
        margin: 32px auto;
    }
    @media ${device.laptop} {
        display: flex;
        flex-direction: column;
        .footer-logo {
            height: 120px;
        }
        .desktop-flex {
            flex-direction: row;
            width: 1020px;
            justify-content: space-between;
            display: flex;
        }
    }
    @media ${device.laptopL} {
        .desktop-flex {
            width: 1280px;
        }
    }
`;

const Social = styled.section`
    align-items: baseline;
    width: 340px;
    display: flex;

    p {
        margin-right: 32px;
        font-weight: 400;
        font-size: 18px;
        line-height: 152.5%;
        color: ${colors.gray4};
    }
    div {
        align-items: baseline;
        width: 200px;
        justify-content: space-between;
        display: flex;
    }
`;

const Navigations = styled.div`
    h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 144%;
        margin-bottom: 0;
        text-transform: capitalize;
        color: ${colors.yellow4};
        letter-spacing: -0.1px;
    }
    ul {
        list-style: none;
        li {
            color: #353633;
            margin: 16px 0px;
        }
    }
    .flex-lists {
        width: 340px;
        display: flex;
        justify-content: space-between;
    }
    @media ${device.laptop} {
        margin-top: 16px;
    }
`;

const GreyPanel = styled.div`
    background: ${colors.gray5};
    text-align: center;
    padding: 8px 0px;
    align-self: stretch;
    margin-top: 32px;
    p {
        color: ${colors.white};
    }
`;

export const Footer = () => {
    return (
        <FooterContainer>
            <Logo className="footer-logo" aria-label="clermont logo" alt="clermont kids logo" />
            <div className="desktop-flex">
                <div>
                    <ContactInfo color="yellow" />
                    <Social>
                        <p>Follow us</p>
                        <div>
                            <a
                                aria-label="google logo"
                                target="__blank"
                                rel="noopener noreferrer"
                                href="https://www.google.com/search?q=google%20clermont%20kids%20dental&sxsrf=ALiCzsYs5ESGqXF6cU9-CXy1fDJQ_jUYuA:1670453342127&ei=WxiRY7-nCZLQgQbjw7PgDA&ved=2ahUKEwiC28jQy-j7AhU2QEEAHVRFBRIQvS56BAgKEAE&uact=5&oq=google+clermont+kids+dental&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzoKCAAQRxDWBBCwA0oECEEYAEoECEYYAFD1AVjFBWDKBmgBcAF4AIABeIgB8gWSAQMxLjaYAQCgAQHIAQXAAQE&sclient=gws-wiz-serp&tbs=lf:1,lf_ui:2&tbm=lcl&rflfq=1&num=10&rldimm=7321395353424734529&lqi=Chtnb29nbGUgY2xlcm1vbnQga2lkcyBkZW50YWwiA4gBAUiT9sSdvq2AgAhaJhABEAIYABgBGAIiFGNsZXJtb250IGtpZHMgZGVudGFsKgQIAxABkgERcGVkaWF0cmljX2RlbnRpc3SqAS8QARofEAEiG1fdwPwlRT1ojIm5d8AVCFO55RZNutgyoIR8cSoKIgZkZW50YWwoAA&sa=X&rlst=f#rlfi=hd:;si:7321395353424734529,l,Chtnb29nbGUgY2xlcm1vbnQga2lkcyBkZW50YWwiA4gBAUiT9sSdvq2AgAhaJhABEAIYABgBGAIiFGNsZXJtb250IGtpZHMgZGVudGFsKgQIAxABkgERcGVkaWF0cmljX2RlbnRpc3SqAS8QARofEAEiG1fdwPwlRT1ojIm5d8AVCFO55RZNutgyoIR8cSoKIgZkZW50YWwoAA;mv:[[28.554108499999998,-81.7071715],[28.5207837,-81.7764713]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!2m1!1e3!3sIAE,lf:1,lf_ui:2"
                            >
                                <Google role="img">
                                    <title>Google</title>
                                </Google>
                            </a>
                            <a
                                aria-label="facebook logo"
                                target="__blank"
                                rel="noopener noreferrer"
                                href="https://www.facebook.com/ClermontKidsDentistry/"
                            >
                                <Facebook role="img">
                                    <title>Facebook Logo</title>
                                </Facebook>
                            </a>
                            <a
                                aria-label="instagram logo"
                                target="__blank"
                                rel="noopener noreferrer"
                                href="https://www.instagram.com/clermont_kids_dentistry/?hl=en/"
                            >
                                <Instagram role="img">
                                    <title>Instagram</title>
                                </Instagram>
                            </a>
                            <a
                                aria-label="yelp logo"
                                target="__blank"
                                rel="noopener noreferrer"
                                href="https://www.yelp.com/biz/clermont-kids-dentistry-clermont"
                            >
                                <Yelp role="img">
                                    <title>Yelp</title>
                                </Yelp>
                            </a>
                        </div>
                    </Social>
                </div>
                <Navigations>
                    <h3>Navigations</h3>
                    <div className="flex-lists">
                        <ul>
                            <li>
                                <Link to="/about-us/who-we-are/">Who We Are</Link>
                            </li>

                            <li>
                                <Link to="/about-us/dr-farad-tartibi/">Meet Dr. Tartibi </Link>
                            </li>
                            <li>
                                <Link to="/for-patients/your-first-visit/">First Visit</Link>
                            </li>
                            <li>
                                <Link to="/about-us/blog/">Blog</Link>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <Link to="/our-services/preventive-dentistry/dental-exams/">
                                    Dental Exams
                                </Link>
                            </li>
                            <li>
                                {' '}
                                <Link to="/our-services/preventive-dentistry/cleanings/">
                                    Teeth Cleaning{' '}
                                </Link>
                            </li>

                            <li>
                                {' '}
                                <Link to="/our-services/dental-emergencies/">
                                    Dental Emergencies{' '}
                                </Link>
                            </li>
                            <li>
                                {' '}
                                <Link to="/our-services/preventive-dentistry/sealants/">
                                    Sealants{' '}
                                </Link>
                            </li>
                        </ul>
                    </div>
                </Navigations>
            </div>
            <GreyPanel>
                <p>© 2022 CLERMONT KIDS DENTISTRY</p>
            </GreyPanel>
        </FooterContainer>
    );
};
